import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { useFirebase } from "gatsby-plugin-firebase"

import { IoIosPeople } from "react-icons/io";

import { RecruitmentAgenciesForm } from '../forms/RecruitmentAgenciesForm';

const RecruitmentAgenciesPageTemplate = ({ title, subtitle, meta_title, meta_description }) => {
  useFirebase(firebase => {
    firebase
      .analytics()
      .logEvent("recruitment_agencies_page")
  }, [])

  return <div>
    <Helmet>
      <title>{meta_title}</title>
      <meta name='description' content={meta_description} />
    </Helmet>

    <section className='hero is-wheat is-bold'>
      <div className='hero-body'>
        <div className='container'>
          <div className='columns is-vcentered'>

            <div>
              <div className='section'>
                <h1 className='title has-text-weight-semibold is-size-3'>{title}</h1>
                <h2 className='is-size-5 mb-1'>{subtitle}</h2>

                <h2 className='has-text-justified mt-1 is-size-6'>
                Enhance your services by partnering with us. We are also open for any suggestions you may have to help your recruitment agencies
                </h2>                
              </div>
            </div>

            <div className="column is-6 is-flex is-horizontal-center is-hidden-mobile">
              <figure className="image is4by3 mt-2">
                <IoIosPeople size={340} />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className='section'>
      <div className='container'>
        <h2 className='is-size-5 mb-2'>For any interest, please fill in the form below.</h2>

        <RecruitmentAgenciesForm />
      </div>
    </section>
  </div>
}

RecruitmentAgenciesPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
}

export default RecruitmentAgenciesPageTemplate
