import React, { useContext } from "react"
import { Formik, Field } from 'formik'
import { navigate } from 'gatsby-link'
import validationSchema from './validationSchema'
import { FirebaseContext } from "gatsby-plugin-firebase"

const RecruitmentAgenciesForm = () => {
  const firebase = useContext(FirebaseContext)

  return (
    <Formik
      initialValues={{ name: '', email: '', phone: '', message: '' }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const { name, email, phone, message } = values;
        firebase.analytics().logEvent("recruitment_agencies_form_submission")

        firebase
          .firestore()
          .collection("/recruitment_agencies_form")
          .add({
            name,
            email,
            phone,
            message,
            created: firebase.firestore.Timestamp.fromDate(new Date())
          })
          .then(() => {
            navigate('/recruitment-agencies/success')
            setSubmitting(false)
          })
          .catch(error => {
            console.log(error)
            alert("Error: Please Try Again!");                            //eslint-disable-line
            setSubmitting(false)
          })
      }}
      render={({
        errors,
        touched,
        isSubmitting,
        handleSubmit,
        handleReset,
      }) => (<form
        name='RecruitmentAgencies'
        onSubmit={handleSubmit}
        onReset={handleReset}
      >
        <div className='field'>
          <label className='label'>Name*</label>
          <div className='control'>
            <Field className='input' type='text' placeholder='Name' name='name' id='name' />
          </div>
          {touched.name && errors.name && <small className='has-text-danger'>{errors.name}</small>}
        </div>

        <div className='field'>
          <label className='label'>Email*</label>
          <div className='control'>
            <Field className='input' type='email' placeholder='Email' name='email' id='email' />
          </div>
          {touched.email && errors.email && <small className='has-text-danger'>{errors.email}</small>}
        </div>

        <div className='field'>
          <label className='label'>Phone Number*</label>
          <div className='control'>
            <Field className='input' type='tel' placeholder='Phone Number' name='phone' id='phone' />
          </div>
          {touched.phone && errors.phone && <small className='has-text-danger'>{errors.phone}</small>}
        </div>

        <div className='field'>
          <label className='label'>Message*</label>
          <div className='control'>
            <Field className='textarea' component='textarea' placeholder='Message' name='message' id='message' rows='6' />
          </div>
          {touched.message && errors.message && <small className='has-text-danger'>{errors.message}</small>}
        </div>

        <div className='field is-grouped is-pulled-right'>
          <div className='control'>
            <button className='button' type='reset'>Clear</button>
          </div>
          <div className='control'>
            <button className='button is-primary' type='submit' disabled={isSubmitting}>Submit</button>
          </div>
        </div>
      </form>)}
    />
  )
}

export { RecruitmentAgenciesForm }
